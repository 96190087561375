import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useMemo, useState } from 'react'
import * as Yup from 'yup'
import {
  EMPTY_LANG_FIELD,
  LANGS,
  LANGS_WITHOUT_DEFAULT,
  MAIN_LANG,
} from '../consts'
import { useAuthorsForSelect } from '../hooks/authors'
import { useKeywords } from '../hooks/keywords'
import { BaseClip, Clip } from '../types'
import {
  DateField,
  InputField,
  MapField,
  MultiSelectField,
  TextareaField,
  transformErrorForForm,
} from './form'
import L from 'leaflet'
import { Button } from 'reactstrap'
import { useGeolocation } from '../hooks/geolocation'
import SortableSuggestedClips from './SortableSuggestedClips'
import Footer from './Footer'

// delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

function VideoPreview({ url }: { url: string }) {
  return (
    <video width="400" controls>
      <source src={url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    // <video
    //   title="Video Preivew"
    //   src={url}
    //   width="100%"
    //   height={200}
    // />
  )
}

const CLipSchema = Yup.object().shape({
  title: Yup.object(
    Object.fromEntries(
      LANGS.map((l) => [
        l,
        (l === MAIN_LANG ? Yup.string().required() : Yup.string()).label(
          'Titolo'
        ),
      ])
    )
  ),
  description: Yup.object(
    Object.fromEntries(LANGS.map((l) => [l, Yup.string().label('Descrizione')]))
  ),
  video_source: Yup.string().label('Video').required(),

  date: Yup.string().nullable().label('Data'),
})

interface Props {
  clip?: Clip
  save: (serie: Clip) => Promise<unknown>
}

export const initialThemeValues: BaseClip = {
  title: EMPTY_LANG_FIELD,
  description: EMPTY_LANG_FIELD,
  archive: '',
  format: '',
  date: null,
  date_precision: 'day',
  author: null,
  url_content: '',
  file_url_vimeo: '',
  video_source: '',
  address: '',
  city: '',
  province: '',
  keywords: [],
  suggested_clips_data: [],
  position: null,
}

export default function ClipForm({ clip, save }: Props) {
  const [lang, setLang] = useState('en')
  const { data: authors } = useAuthorsForSelect()
  const { data: keywords } = useKeywords()
  const geolocationAction = useGeolocation()

  const keyWordsToUse = useMemo(() => {
    return keywords?.map((k) => ({
      value: k.id,
      label: k.value.it,
    }))
  }, [keywords])

  return (
    <Formik
      enableReinitialize
      onSubmit={(clip, { setErrors }) =>
        save(clip).catch((error) => {
          setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={CLipSchema}
      initialValues={(clip ?? initialThemeValues) as Clip}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Informazioni</h4>
              <div>
                {LANGS_WITHOUT_DEFAULT.map((language) => (
                  <div
                    key={language}
                    className={classNames({
                      'lang-active': language === lang,
                      'lang-non-active': language !== lang,
                    })}
                    onClick={() => setLang(language)}
                  >
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Field
                required
                label={`Titolo [it]`}
                name={`title.it`}
                component={InputField}
              />
              <Field
                label={`Descrizione [it]`}
                name={`description.it`}
                component={TextareaField}
                rows={5}
              />
            </div>
            <div className="col-md-6">
              <Field
                label={`Titolo [${lang}]`}
                name={`title.${lang}`}
                component={InputField}
              />
              <Field
                label={`Descrizione [${lang}]`}
                name={`description.${lang}`}
                component={TextareaField}
                rows={5}
              />
            </div>
            <div className="col-md-6">
              <Field
                required
                label={'Video'}
                placeholder="Vimeo url or id"
                name={`video_source`}
                component={InputField}
              />
              {values.file_url_vimeo && (
                <VideoPreview url={values.file_url_vimeo} />
              )}
            </div>
            <div className="col-md-6">
              <Field
                name="keywords"
                component={MultiSelectField}
                label={'Keywords'}
                options={keyWordsToUse}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Informazioni aggiuntive</h4>
            </div>
            <div className="col-md-6">
              <Field label="Archivio" component={InputField} name="archive" />
            </div>
            <div className="col-md-6">
              <Field label="Formato" component={InputField} name="format" />
            </div>
            <div className="col-md-4">
              <Field name="date" label={'Data'} component={DateField} />
            </div>
            <div className="col-md-4">
              <label>Precisione Data</label>
              <select
                onChange={(e) =>
                  setFieldValue('date_precision', e.target.value)
                }
                value={values.date_precision || 'day'}
                className="form-select"
              >
                <option value={'day'}>Giorno</option>
                <option value={'month'}>Mese</option>
                <option value={'year'}>Anno</option>
              </select>
            </div>
            <div className="col-md-4">
              <label>Autore</label>
              <select
                onChange={(e) => setFieldValue('author', e.target.value)}
                value={values.author || ''}
                className="form-select"
              >
                <option value={''}></option>
                {authors?.map((author) => (
                  <option key={author.id} value={author.id}>
                    {author.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 mt-4">
              <h4>Posizione</h4>
            </div>

            <div className="col-md-4">
              <Field name="city" component={InputField} label={'Città'} />
            </div>
            
            
            <div className="col-md-5">
              <Field name="position" component={InputField} label={'Coordinate'} />
            </div>
          </div>
          <div>
            <SortableSuggestedClips excludeId={clip?.id} />
          </div>
          <Footer>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </Footer>
        </form>
      )}
    </Formik>
  )
}
