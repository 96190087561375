import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Author } from '../../types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Page from '../../components/Page'
import Header from '../../components/Header'
import { useAuthor, useDeleteAuthor, useUpdateAuthor } from '../../hooks/authors'
import AuthorForm from '../../components/AuthorForm'

export default function AuthorEdit() {
  const { id } = useParams()
  const { data: author } = useAuthor(+id!)
  const deleteAuthor = useDeleteAuthor()
  const updateAuthor = useUpdateAuthor()
  const [modalRm, modalRmActions] = useModalTrigger<Author>()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/authors">Autori</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {author!.name}
            </li>
          </ol>
        </nav>
        <button
          className="btn  btn-danger"
          onClick={() => modalRmActions.open(author)}
        >
          <i className="bi bi-trash3-fill"></i> Elimina
        </button>
      </Header>

      <div className="py-3 overflow-y-auto flex-1">
        <AuthorForm
          author={author}
          save={(formAuthor) =>
            updateAuthor.mutateAsync(formAuthor).then(() =>
              toast.success('Autore salvato con successo!', {
                position: 'bottom-right',
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'autore {modalRm.value.name} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btnoutline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteAuthor.isLoading}
            onClick={async () => {
              await deleteAuthor.mutateAsync(modalRm.value!.id)
              navigate('/authors')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
