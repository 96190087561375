import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import Auth from './Auth'
import Layout from './components/Layout'
import NotFound from './components/NotFound'
import DataProvider from './DataProvider'
import ErrorBoundary from './ErrorBoundary'
import { RequireAuth, RequireGuest } from './routing'
import RecoverPassword from './pages/RecoverPassword'
import ResetPassword from './pages/ResetPassword'
import Login from './pages/Login'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Users from './pages/Users'
import ActivateAccount from './pages/ActivateAccount'
import { User } from './types'
import Profile from './pages/Profile'
import Series from './pages/Series'
import Contents from './pages/Contents'
import Authors from './pages/Authors'
import Clips from './pages/Clips'
import Extras from './pages/Extras'
import ViteArchivio from './pages/ViteArchivio/ViteArchivio'
import Keywords from './pages/Keywords/Keywords'
import SlidesHome from './pages/SlidesHome/SlidesHome'
import BlocksHomePage from './pages/BlocksHomePage'
import Territories from './pages/Territories'

function checkUserIsStaff(user: User) {
  if (!user.is_staff) {
    return '/'
  }
}

export default function App() {
  return (
    <ErrorBoundary>
      <DataProvider>
        <Auth>
          <Router>
            <Routes>
              {/* GUEST */}
              <Route
                path="/"
                element={
                  <RequireGuest>
                    <Outlet />
                  </RequireGuest>
                }
              >
                <Route path="login" element={<Login />} />
                <Route path="password-recover" element={<RecoverPassword />} />
                <Route
                  path="password-reset/:token"
                  element={<ResetPassword />}
                />
                <Route
                  path="activate-account/:token"
                  element={<ActivateAccount />}
                />
              </Route>
              {/* AUTH */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route index element={<Navigate to="/profile" />} />
                <Route path="contents/*" element={<Contents />} />
                <Route path="clips/*" element={<Clips />} />
                <Route path="extras/*" element={<Extras />} />
                <Route path="authors/*" element={<Authors />} />
                <Route path="profile/*" element={<Profile />} />
                <Route path="series/*" element={<Series />} />
                <Route path="territories/*" element={<Territories />} />
                <Route path="vite-archivio/*" element={<ViteArchivio />} />
                <Route path="keywords/*" element={<Keywords />} />
                <Route path="slides-home/*" element={<SlidesHome />} />
                <Route path="blocks-home-page/*" element={<BlocksHomePage />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="/"
                element={
                  <RequireAuth redirectTest={checkUserIsStaff}>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="users/*" element={<Users />} />
              </Route>
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </Router>
        </Auth>
      </DataProvider>
      <ToastContainer autoClose={3000} draggable={false} />
    </ErrorBoundary>
  )
}
